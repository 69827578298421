import React, { useState, useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import CalendarExpensesContainer from './CalendarExpensesContainer';
import CalendarChecklistContainer from './CalendarChecklistContainer';

interface Props {
    eventId: number;
    eventTypeId?: number;
    calendarDate: string | Date;
    workerId: number;
    admin: boolean;
}

const getTabFromUrl = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get("tab") || "expenses-data"; // Si no hay parámetro, usa "expenses-data" por defecto
};

const ExpensesAndChecklists = ({ 
    eventId, 
    eventTypeId,
    calendarDate,
    workerId, 
    admin,
}: Props) => {
    const [activeTab, setActiveTab] = useState(getTabFromUrl());

    useEffect(() => {
        const handleUrlChange = () => {
            setActiveTab(getTabFromUrl());
        };

        window.addEventListener("popstate", handleUrlChange);

        return () => {
            window.removeEventListener("popstate", handleUrlChange);
        };
    }, []);

    return (
        <div>
            <Tabs 
                id="expenses-checklists-tabs"
                className="mb-3"
                activeKey={activeTab} 
                onSelect={(k) => {
                    if (k) {
                        setActiveTab(k);
                        const newUrl = new URL(window.location.href);
                        newUrl.searchParams.set("tab", k);
                        window.history.pushState({}, "", newUrl.toString());
                    }
                }}
            >
                <Tab eventKey="expenses-data" title="Gastos">
                    <CalendarExpensesContainer
                        eventId={eventId}
                        calendarDate={calendarDate}
                        admin={admin}
                        workerId={workerId}
                    />
                </Tab>
                
                <Tab eventKey="checklists-data" title="Encuestas">
                    <CalendarChecklistContainer
                        eventTypeId={eventTypeId}
                        calendarDate={calendarDate}
                    />
                </Tab>
            </Tabs>
        </div>
    )
}

export default ExpensesAndChecklists;
