import React from 'react';
import { UserResponseDetail } from '../../../../app/models/UserResponseDetail';
import FileCarrousel from '../FileCarrousel';
import { InputResponseType } from '../../../../app/shared/enums';
import { momentParseDate, momentParseDateTime } from '../../../../helpers';
import { formatNumber } from '../../../../utils/utils';
import { SimpleUserResponseFile } from '../../../../app/models/UserResponseFile';

interface Props {
    responses: UserResponseDetail[];
}

const UserResponseDetailTable = ({ responses }: Props) => {

    const resTypeFormat = (responseType: InputResponseType, text: string | undefined, options: any[] | undefined) => {
        switch (responseType) {
            case InputResponseType.DATETIME:
                return momentParseDateTime(text);
    
            case InputResponseType.DATE:
                return momentParseDate(text);
    
            case InputResponseType.NUMBER:
                return !isNaN(Number(text)) ? formatNumber(Number(text)) : '';
    
            case InputResponseType.USER:
                if(options) return options[0]?.text || options[0]?.label;
                break;

            case InputResponseType.TRUCK:
                if(options) return options[0]?.text || options[0]?.label;
                break;

            case InputResponseType.HOPPER:
                if(options) return options[0]?.text || options[0]?.label;
                break;

            case InputResponseType.CHECKBOX:
                return options?.filter((option: { is_response: boolean; }) => option.is_response)
                                .map((option: { text: any; }) => option.text) 
                                .join(', ');

            case InputResponseType.SELECT:
                return options?.filter((option: { is_response: boolean; }) => option.is_response)
                                .map((option: { text: any; }) => option.text) 
                                .join(', ');
            
            default:
                return text;
        }
    }

    const getImageUrl = (response: UserResponseDetail): string[] => {

        if (!response.images) return [];
        
        if (typeof response.images[0] === 'string'){
            return response.images as string[];
        } else{
            return (response.images as SimpleUserResponseFile[]).map(img => img.file);
        }
    }

    const renderResponseRow = (response: UserResponseDetail, index: number) => {
        const isFileType = response.response_type === InputResponseType.FILE;
        const hasImages = response.images && response.images?.length > 0;
        return (
            <>
                <tr key={response.id}>
                    <th scope="row" className="align-top">{index + 1}</th>
                    <td className="align-top">{response.question_text}</td>
                    <td className="align-top">
                        {response.response_type === "RADIOBUTTON" ? (
                            response.options?.map((option) =>
                                option.is_response ? <p key={option.id}>{option.text}</p> : null
                            )
                        ) : (
                            !isFileType && (
                                <p>
                                    {resTypeFormat(
                                        response.response_type as InputResponseType,
                                        response.question_value || response.text,
                                        response.options
                                    )}
                                </p>
                            )
                        )}
                    </td>
                </tr>
                {response.comment && (
                    <tr>
                        <th scope="row"></th>
                        <td colSpan={2}>
                            <strong>Observaciones:   </strong>
                            { response.comment }
                        </td>
                    </tr>
                )}
                {hasImages && response.images && (
                    <tr>
                        <th scope="row"></th>
                        <td colSpan={2}>
                            <FileCarrousel imageURLs={ getImageUrl(response) } />
                        </td>
                    </tr>
                )}
            </>
        );
    };

    
    return (
        <div>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Pregunta</th>
                        <th scope="col">Respuesta</th>
                    </tr>
                </thead>
                <tbody>
                    {responses.map((response, index) => renderResponseRow(response, index))}
                </tbody>
            </table>
        </div>
    )
}

export default UserResponseDetailTable
